.sidebar-sticky {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.blog-h1 {
  font-size: 53px;
  /*   border-bottom: 1px solid #61bf95; */
  margin-bottom: 30px;
  padding-right: 32px;
}

.blog-h2 {
  text-transform: uppercase;
  font-size: 22px;
  border-bottom: 1px solid #61bf95;
}

.spererate {
  border-bottom: 1px solid #61bf95;
}

.blog-h4 {
  font-size: 20px;
}

.block-70-left {
  width: 70%;
}

.text-justify {
  text-align: justify;
}

.badge {
  max-width: 148px;
  padding: 0px 10px !important;
  color: #fff !important;
  float: none;
  display: inline-flex !important;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  height: 40px;
  line-height: 1.2;
  background-color: #61bf95 !important;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  margin-bottom: 13px;
}

p textarticle {
  text-align: justify;
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 320px) and (max-width: 1025px) {
  .blogRightBar {
    display: none;
  }
  .block-70-left {
    width: 100%;
  }
  .top-contact {
    flex-direction: column-reverse !important;
  }
  .blog-h1 {
    font-size: 23px;
    line-height: 150%;
  }
}
@media screen and (max-width: 320px) {
  .blogRightBar {
    display: none;
  }
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.1em;
  /* padding: 0.625em; */
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
