.getAudit{
    width: 100vw;
    background: linear-gradient(to right,#1E1E1E 50%, #f1f1f1 50%);
    margin: 0;
    display: flex;
}

.blockAudit{
    background: linear-gradient(to right,#1E1E1E 944px, #f1f1f1 376px);
    height: 100%;
    padding: 136px 10px 50px !important;
}

.left-block-35{
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-block-35 > h1 {
    width: 732px;
    z-index: 2;
}

.left-block-35 > p {
    max-width: 398px;
    margin: 30px 0;
}

.mw440{
    max-width: 440px;
}

.right-block{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.right-block-65{
    width: 63%; 
}

.partners{
    background: #f1f1f1;
    height: 100px;
    width: 100vw;
    margin: 0;
}

.partners > .content-block{
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
}

.partners h3{
    flex: 0 0 20%;
    padding: 0;
}

.partners-container, .bandeau_horizontal{
    flex: 0 0 80%;
    padding: 0 10px 0 50px;
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 2em;
    overflow-x: hidden;
    /* align-items: center; */
    position: relative;
}

.defilement, .defilement2{
    display: flex;
    gap: 2.5em;
    white-space: nowrap;
    height: 50%;
    position: relative;
}

.defilement{
    animation: 15s defilement infinite linear;
}

/* .defilement2{
    animation-delay: 13s;
    animation-name: defilement2;
    animation-duration: 15s;
    animation-iteration-count: 1;
    animation-direction: linear;
} */

@keyframes defilement {0% {transform: translateX(20%);} 100% {transform: translateX(-300%);}}
/* @keyframes defilement2 {0% {transform: translateX(100%);} 100% {transform: translateX(-300%);}} */

.presentation{
    background: linear-gradient(to right, #f1f1f1 0 35%, white 35% 75%);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.sliderContainer{
    flex: 1 1 50%;
    overflow: hidden;
}

.sliderPresentation{
    display: flex;
    flex-direction: row;
    transform: translateX(0);
    transition-property: transform;
    transition-duration: 1.5s;
}

.sliderPresentation.move1{
    transform: translateX(-100%);
    transition-property: transform;
    transition-duration: 1.5s;
}

.sliderPresentation.move2{
    transform: translateX(-200%);
    transition-property: transform;
    transition-duration: 1.5s;
}

.presentationButtons{
    position: relative;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    top: -80px;
}

.presentation > div > *:nth-child(2){
    flex: 1 1 50%;
    padding: 35px 0 35px 70px;
}

.presentation > div > *:nth-child(2) > p{
    width: 100%;
}

img {
	max-width: 100%;
	height: auto;
}
.img-actu {
    height: "86%"
}
.mosaic{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
}

.gap-10px{
    flex-wrap: nowrap;
}

.mosaic-block{
    justify-content: stretch;
}

.mosaic-project{
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 200%;
    gap: 0;
    margin-left: -50px;
}

.mosaic-block-project {
    box-sizing: border-box;
    width: 423.3px;
    border-width: 1px;
    border-style: solid;
    border-image: 
        linear-gradient(
        to bottom, 
        transparent 0 12.5%,
        rgba(30, 30, 30, 0.2) 12.5% 70%,
        transparent 70% 100%
        ) 1 100%;
    border-left: none;
    padding: 0 50px;
    flex-direction: column;
}

.moreInfos{
    align-self: flex-end;
    border-bottom: 1px solid #1e1e1e;
}

.project {
    background: linear-gradient(to left, #f1f1f1 0 calc((100vw + 180px - 1320px)/2), white calc((100vw + 180px - 1320px)/2) 75%);    
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.project > .block-content{
    flex-direction: column;
}

.topProject{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.projectButtons{
    position: relative;
}

.project h4{
    width: 75%;
}

.project a {
    font-weight: bold;
    color: #1e1e1e;
}

.moveLeft{
    transform: translateX(-1269px);
    transition-property: transform;
    transition-duration: 1.5s;
}

.moveRight{
    transform: translateX(0px);
    transition-property: transform;
    transition-duration: 1.5s;
}

.solution {
    background: linear-gradient(to right, #1e1e1e 0 35%, white 35% 75%);
    width: 100vw;
    display: flex;
    padding: 25px 0;
}

.solution > .content-block > *:nth-child(1){
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.solution > .content-block > *:nth-child(2){
    flex: 1 1 calc(50% - 160px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 80px;
}

.whyUs {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.whyUs .mosaic-block{
    flex-basis: 15%;
}

.whyUs > :nth-child(1){
    flex-direction: column;
}


.nos-solutions {
    width: 100vw;
    margin: 0;
    background: linear-gradient(to right,white 65%, #61BF95 35%);
    padding: 0 0 70px;
}

.nos-solutions > :nth-child(1){
    flex-direction: column;
}

.nos-solutions select {
    display: none;
}

.solutions {
    width: 100%;
    display: flex;
    gap: 1em;
}

.solutions > *:nth-child(1){
    flex: 1 1 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5em;
}

.solutions > *:nth-child(1) > ul {
    display: flex;
    flex-direction: column;
}

.solutions > *:nth-child(1) > ul > li {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    color: #555555;
    box-sizing: border-box;
}

.solutions > *:nth-child(2){
    flex: 1 1 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fbfbfb;
    box-sizing: border-box;
}

.accordion{
    padding: 0;
    overflow: hidden;
    margin-bottom: 0 !important
}

.active > label {
    cursor: pointer;
    color: #61bf95;
}

.has-subs.active > .sub{
    display: block;
}

.has-subs::before {
    display: none !important;
  }

.sub {
    display: none;
}

.sub > li {
    height: 0;
    overflow: hidden;
    transition: all 0s ease-in-out;
    padding-left: 0;
}

input[type="checkbox"] {
    display: none;
}

input:checked + .sub > li {
    height: 2.5em;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    width: 80%;
    list-style-position: inside;
    list-style-type: disc;
}

.sub{
    margin-top: 5px;
}

.sub > li.active {
    background: rgba(97, 191, 149, 0.1);
    color: #61BF95;
    font-weight: bold !important;
}

.solutions-content{
    padding-right: 2.5em;
}

.blog{
    display: flex;
    flex-direction: column;
    background: #EAEAEA;
    padding: 25px 0;
}

.blog > .content-block {
    min-width: 1320px;
    flex-direction: column;
}

.blog h4{
    font-size: 24px;
    margin: 15px 0;
}

.blog p {
    margin: 10px 0;
}

.blog > .content-block  > .articles {
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 100%;
}

.blog > .content-block > .articles > a > *:nth-child(1){
    flex: 1 1 60%;
    background: white;
    display: flex;
    padding: 0;
    width: 100%;
}

/* .blog > .content-block > .articles > *:nth-child(1) > *:nth-child(1){
    padding: 20px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
} */

.blog > .content-block > .articles > *:nth-child(1) > *:nth-child(1) > *:nth-child(1){
    padding: 20px;
    height: 100%;
    gap: 0.5em;
    display: flex;
    flex-direction: column;
}

.blog > .content-block > .articles > *:nth-child(1) > *:nth-child(2){
    width: 60%;
    padding: 20px;
}


.blog > .content-block > .articles > *:nth-child(2), .blog > .content-block > .articles > *:nth-child(3){
    flex: 1 1 10%; 
    background: white;
    display: flex;
    flex-direction: column;
}

.blog > .content-block > .articles > *:nth-child(2) > div , .blog > .content-block > .articles > *:nth-child(3) > div{
    padding: 20px;
}

.buttonMore{
    width: 273px;
    align-self: center;
}

.youAre {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    background: linear-gradient(to right, #1e1e1e 0 35%, white 35% 75%);
    padding: 25px 0;
}

.youAre > .content-block{
    flex-direction: row;
}

.mosaic-block-youAre{
    width: calc(100vw - 30px);
    height: auto;
}

.fill {
    display: flex;
    justify-content: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    width: 100%;
}

.youAre .mosaic-block {
    flex-basis: 315px;
    display: flex;
    justify-content: center;
   /*  align-items: center; */
}

.youAre .right-block-youAre{
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.youAre h2 {
    margin: 0;
}

.youAre > .mosaic {
    justify-content: space-around;
}

.guarantees {
    width: 100vw;
}

.guarantees > .content-block {
    flex-direction: column;
}

.guarantees > .content-block > div {
    display: flex;
    width: 100%;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.guarantees > div > img {
    flex-basis: 5%;
}

.newsletter{
    background: #DFF1E9;
    /* padding: 25px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.newsletter2{
    background: #DFF1E9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

body #error-block{
    margin-top: -15px !important;
    background: transparent !important;
}

.newsletter > input {
    border: none;
    height: 48px;
    padding: 15px 20px;
    margin-left: 50px;
    width: 260px;
    font-size: 16px;
    line-height: 19px;
}

.newsletter > h3 {
    width: 550px;
}

.resp-fixed-solution{
    display: none;
}

.responsive{
    display: flex !important;
    align-items: center !important;
}


/* Small devices (landscape phones, 320px and up) */
@media only screen and (min-width: 320px) and (max-width: 1025px) {
    .line{
        background: linear-gradient(to right, #1E1E1E 25px, #f8f8f8 25px calc(100% - 25px), #1E1E1E calc(100% - 25px) 100%);
        height: 1px;
    }

    .img-actu {
        height: "auto%"
    }

    .blockAudit{
        background: linear-gradient(to right,#1E1E1E 944px, #f1f1f1 376px);
        height: 100%;
        padding: 30px 10px !important;
    }

    .getAudit{
        width: 100vw;
        background: #1e1e1e;
        margin: 0;
        display: flex;
        padding-top: 50px;
    }
    
    .left-block-35{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .left-block-35 > h1{
        margin: 10px 0;
    }

    .left-block-35 {
        max-width: 100%;
        text-align: left;
    }


    .right-block-65{
        width: 100%;
    }
    
    .mw440{
        max-width: 100% !important;
    }

    .partners > .content-block {
        flex-wrap: nowrap
    }

    .partners > .content-block > h3 {
        font-weight: bold;
        font-size: 14px;
        line-height: 26px;
    }
    
    .bandeau_horizontal{
        flex: 0 0 70%;
        height: 50%;
    }

    .presentation{
        background: #fff;
        display: flex;
        min-height: 100px;
        padding: 0;
        margin: 0;
    }

    .presentation > .content-block > *:nth-child(1){
        order: 2;
    }

    .sliderContainer{
        flex: 1 1 100%;
        margin-top: 25px !important;
    }
    
    .presentation > div > *:nth-child(2){
        flex: 1 1 100%;
        padding: 0 0 50px 0;
    }

    .project{ 
        margin: 0 0 25px;
        padding: 0;
    }

    .topProject{
        flex-direction: column;
        margin-bottom: 25px;
    }

    .topProject > .projectButtons{
        display: none;
    }

    .project p {
        width: 100%;        
        margin: 10px 0 0;
    }

    .project > :nth-child(2) {
        margin: 20px auto;
    }

    .mosaic{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: 1em;
    }
    
    .mosaic-block{
        flex-basis: 100% !important;
        display: flex;
        flex-direction: column;
    }

    .mosaic-block-project {
        flex: 2 0 40% !important;
        padding: 0;
        min-height: 80px;
        border: none;
    }

    .mosaic-block-project > div {
        display: flex;
        flex-direction: column;
        background: #61BF95;
        color: white;
        justify-content: center;
        align-items: center;
        padding: 20px 10px;
        gap: 0.5em;
        height: 100%;
        width: 100%;
    }

    .project h4{
        width: 100%;
    }

    .mosaic-block-project > div > img{
        width: 15%;
    }

    .project .mosaic-block-project p, .project .mosaic-block-project a.moreInfos {
        display: none;
    }

    .mosaic-block-project h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: white !important;
        text-align: center;
    }

    .solution{
        background: #1E1E1E;
        padding: 20px 0;
    }

    .solution h2{
        color: white;
        margin: 0;
        padding: 0;
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 41px;
    }

    .solution > .content-block > .image-block{
        display: none;
    }

    .solution  p {
        color: white;
    }

    .solution > .content-block > *:nth-child(2) {
        padding: 0;
    }

    .solution button {
        margin-bottom: 0 !important;
    }

    .whyUs {
        width: 100vw;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .whyUs h2, .whyUs h4, .whyUs p {
        text-align: left !important;
    }

    .whyUs h2, .whyUs h4{
        margin-bottom: 20px;
    }

    .whyUs p {
        width: 80vw;        
        margin: 0px;
        text-align: left;
    }

    .whyUs img{
        width: 10%;
        margin-bottom: 20px;
    }

    .whyUs .padding-20{
        padding: 0;
    }

    .nos-solutions {
        background: #fff;
        padding-bottom: 0;
    }

    .nos-solutions select {
        display: block;
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid black;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
    }

    .solutions{
        flex-direction: column;
    }
    
    .solutions > *:nth-child(1){
        flex: 1 1 90%;
    }
    
    .solutions > *:nth-child(1) > ul {
        display: none;
    }
    
    .solutions > *:nth-child(1) > ul > li {
        font-size: 16px;
        line-height: 20px;
    }
    
    .solutions > *:nth-child(2){
        flex: 1 1 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        border: 3px solid #F8F8F8;
        box-sizing: border-box;
        margin-right: 0;
        padding: 0;
    }

    .solutions img{
        display: none;
    }

    .accordion{
        display: none;
    }

    .solutions-content{
        padding-right: 0;
    }

    /*   */
    .blog{
        display: flex;
        flex-direction: column;
        background: white;
        padding: 0;
    }
    
    .blog > .content-block {
        min-width: 0px;
        width: 100%;
        flex-direction: column;
    }

    .blog h2.center{
        text-align: left !important;
        font-family: "NotoSerif";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
    }
    
    .blog h4{
        font-size: 24px;
        margin: 10px 0;
    }
    
    .blog p {
        margin: 10px 0;
    }
    
    .blog > .content-block  > .articles {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 100%;
        margin: 0;
    }
    
    .blog > .content-block > .articles > *:nth-child(1){
        flex: 1 1 100%;
        background: white;
        display: flex;
        padding: 20px;
    }

    .blog h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.014em;
    }
    
    .blog > .content-block > .articles > *:nth-child(1) > .article > *:nth-child(1){
        display: none;
    }
    
    .blog > .content-block > .articles > *:nth-child(1) > .article > *:nth-child(2){
        width: 100%;
        padding: 0;
    }
    
    
    .blog > .content-block > .articles > *:nth-child(2), .blog > .content-block > .articles > *:nth-child(3){
        display: none;
    }
    /*   */


    .youAre {
        background: #fff;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
    }

    .youAre .mosaic {
        width: 400%;
        display: flex;
        margin: 0;
        grid-gap: 1em;
        gap: 1em;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .youAre > .content-block {
        flex-direction: column;
    }

    .youAre .right-block-youAre {
        padding: 0;
    }

    .youAre .mosaic-block {
        flex-basis: 100%;
    }

    .youAre h2 {
        margin: 20px 0;
    }

    .youAre p {      
        margin: 0px;
        margin: 0 0 20px;
    }

    .buttonMore{
        width: 100%;
    }

    .guarantees{
        margin: 25px 0;
    }
    
    .guarantees > .content-block > div {
        flex-direction: row;
        gap: 2em;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
    }

    .newsletter{
        background: #DFF1E9;
        padding: 25px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .newsletter2{
        background: #DFF1E9;
        height: 375px!important;
        padding: 25px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }    
    .newsletter > input {
        border: none;
        height: 48px;
        padding: 0 20px;
        margin: 20px 0;
        width: 100%;
    }
    
    .newsletter > h3 {
        width: 100%;
        min-width: 0;
    }

    .resp-fixed-solution{
        display: block;
        position: fixed;
        bottom: 0;
        margin: 0;
        z-index: 4;
    }

    .resp-fixed-solution h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        width: 90%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 1025px) {}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 1026px) and (max-width: 1365px) {
    .project {
        background: none;
    }

    .mosaic-block-youAre{
        flex-basis: 300px !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 2000px) {
    .presentation,
    .project,
    .solution,
    .nos-solutions,
    .youAre{
        background: white;
        margin: 25px 0;
    }

    .nos-solutions{
        padding: 0;
    }
}