.Impots {
    background: white;
  }
  
  .Impots > .content-block > .block-content {
    flex-wrap: wrap;
    gap: 2.5em;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
  }
  
  .Impots > .content-block > .block > .mosaic-block {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #61bf95;
    flex: 1 1 30%;
  
    min-width: 150px;
    min-height: 300px;
  }
  
  .Impots > .content-block > .block > .mosaic-block > h3 {
    font-family: "Inter";
    font-size: 20px;
  }
  
  .Impots > .content-block > .block > .mosaic-block > a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
  
    text-decoration-line: underline;
  
    color: #00a76d;
  }
  
  .mosaic-block-article {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  
  .mosaic-block-2 {
    width: 360px;
    height: 490px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
    border: none !important;
  }
  
  .Impots > .block > .content-block {
    flex-direction: row;
  }
  
  .Impots .content-block {
    flex-direction: column;
  }
  
  .newsletter .content-block {
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  
  form.contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin: 25px auto;
  }
  
  form.contact input,
  form.contact textarea {
    border: 1.2px solid #b4bec8;
    box-sizing: border-box;
    padding: 15px;
    min-width: 313px;
  }
  
  .top-contact {
    width: 100%;
    justify-content: space-between;
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media only screen and (min-width: 320px) and (max-width: 1025px) {
    .top-contact {
      flex-direction: column-reverse !important;
    }
  }
  
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
  }
  
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 0.35em;
  }
  
  table th,
  table td {
    padding: 0.1em;
    /* padding: 0.625em; */
    text-align: center;
  }
  
  table th {
    font-size: 0.85em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  
  /* @media screen and (max-width: 600px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
  
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: 0.625em;
    }
  
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 0.8em;
      text-align: right;
    }
  
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
  
    table td:last-child {
      border-bottom: 0;
    }
  } */
  