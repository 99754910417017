.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  /*   width: 90%; */
  /*     height: auto; */
  /*     max-height: 50px;
    max-width: 250px; */
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 300px) {
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
  .swiper-slide img {
    max-width: 100%;
    max-height: 100%;
  }
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-slide {
    width: auto !important;
    height: auto !important;
  }

  .swiper-slide img {
    max-width: 150px;
    max-height: 70%;
  }
  .swiper-container {
    width: 768px;
  }
}
