
.Audit{
    display: none;
}

.filter.activeAudit{
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.5);
}

.Audit.activeAudit{
    width: 100%;
    padding: 80px 10px 0;
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;
}

.auditBlock{
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auditBlock h2{
    margin: 10px 0 50px !important;
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
}

.auditBlock p {
    margin: 0;
}

.contentAudit {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contentAudit button.button1 {
    border: 2px solid #A3A3A3;
    box-sizing: border-box;
    width: 130px;
    height: 120px;
    background: white;
    color: #A3A3A3;
    flex-wrap: wrap;
}

.contentAudit input{
    border: none;
    border-bottom: 2px solid #a3a3a3;
    font-size: 40px;
}

.col-3{
    gap: 1em;
    display: flex;
}

.col-3 > input {
    width: 25%;
    flex: 1 1 30%;
}

.contentAudit button.button1:hover {
    border: 2px solid #61BF95;
    box-sizing: border-box;
    width: 130px;
    height: 120px;
    background:rgba(97, 191, 149, 0.8);
    color: white;
    flex-wrap: wrap;
    font-weight: bold;
}

.contentAudit button.button1.active {
    border: 2px solid #61BF95;
    box-sizing: border-box;
    width: 130px;
    height: 120px;
    background:rgba(97, 191, 149, 0.8);
    color: white;
    flex-wrap: wrap;
    font-weight: bold;
}

.contentAudit form{
    display: flex;
}

.contentButtons{
    display: flex;
    gap: 1em;
    justify-content: center;
}

.previousButton, .nextButton{
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
}

.previousButton{
    /* border: 2px solid #a3a3a3; */
    background: #eaeaea;
    color: #a3a3a3;
}

.nextButton{
    align-self: flex-end;
}

.nextButton.disabled{

    background: #eaeaea;
    color: #a3a3a3;
    cursor: auto;
}

.underline{
    border-bottom: 4px solid #1e1e1e;
}

.topAuditQuestionnaire{
    width: 100%;
    padding: 0 30px 0 20px;
}

.topAuditQuestionnaire > p {
    border-bottom: 1px solid white;
    cursor: pointer;
}

.topAuditQuestionnaire > p:hover {
    border-bottom: 1px solid #61BF95;
    cursor: pointer;
}

.large{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.small, .progressBar{
    display: none;
}

.pStep{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #A3A3A3;
    display: block;
}

.message{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px;
    margin-top: 25px;
}

.sended{
    color:rgba(97, 191, 149, 1);
    background: rgba(97, 191, 149, 0.3);
}

.notSended{
    color: rgb(224, 59, 59);
    background: rgb(238, 184, 184);
}

p.sended, p.notSended{
    background: transparent;
    margin: 0;
    font-weight: bold;
    text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 1025px) {
    .Audit.activeAudit{
        padding-top: 50px;
    }

    .Audit h2{
        font-family: "Inter";
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: -0.02em;
    }

    .Audit p{
        text-align: left;
        font-family: "Inter";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #A3A3A3;
    }

    .contentButtons{
        flex-direction: column;
        width: 100%;
    }

    .contentAudit{
        justify-content: flex-start;
    }

    .contentAudit button.button1 {
        border: 2px solid #A3A3A3;
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-weight: bold;
        background: white;
        color: #1e1e1e;
        flex-wrap: wrap;
        text-align: left;
        padding-left: 15px;
    }

    .contentAudit button.button1:hover,
    .contentAudit button.button1.active {
        width: 100%;
        height: 50px;
    }

    .contentAudit input{
        border: none;
        border-bottom: 2px solid #a3a3a3;
        font-size: 40px;
        width: 100%;
    }

    .topAuditQuestionnaire{
        padding: 0;
    }

    .large{
        display: none;
    }

    .small{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .small p{
        font-family: "Roboto";
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #A3A3A3;
    }

    span.color-1e1e1e{
        color: #1e1e1e;
    }

    .progressBar{
        display: block;
        background: #A3A3A3;
        border-radius: 10px;
        width: 100%;
        height: 8px;
    }

    .progression{
        background: #61BF95;
        border-radius: 10px;
        width: 10%;
        height: 8px;
    }

    .p1{
        width: 10%;
    }
    .p2{
        width: 20%;
    }
    .p3{
        width: 30%;
    }
    .p4{
        width: 40%;
    }
    .p5{
        width: 50%;
    }
    .p6{
        width: 60%;
    }
    .p7{
        width: 70%;
    }
    .p8{
        width: 80%;
    }
    .p9{
        width: 90%;
    }
    .p10{
        width: 100%;
    }
    
    .col-3{
        gap: 1em;
        display: flex;
        flex-wrap: wrap;
    }
    
    .col-3 > input {
        flex: 1 1 100%;
        margin-bottom: 15px;
    }
}