nav{
    background: linear-gradient(to right,#1E1E1E 50%, #f1f1f1 50%);
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 11;
    top: 0;
}
.h1menu{
    font-size: 42px;
    line-height:normal;
}

.menu-block{
    width: 1320px;
    background: linear-gradient(to right,#1E1E1E 944px, #f1f1f1 376px);
    margin: 0 auto;
    display: flex;
    padding: 0 10px 0 0;
    justify-content: space-between;
    align-items: center;
}

.menu-block.light{
    background: #f8f8f8 !important;
}

.menu-block.dark{
    background: #1E1E1E;
}

.menu-block > .getAnAudit {
    margin-top: 18px;
}

nav.dark{
    background: #1e1e1e;
}

nav img.logo{
    width: 270px;
    margin-left: 10px;
}

nav > div {
    flex-wrap: wrap;
}

.your-project {
    margin: 0;
    width: 100%;
    display: flex;
    position: relative;
}

.your-project .content-block a {
    text-decoration: none;
}

.your-project > div > :nth-child(1){
    flex: 0 0 17%;
    border-right: 1px solid #61BF95;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.your-project > div > :nth-child(1) > h1 {
    font-size: 36px;
    width: 70%;
    line-height: 50px;
    font-weight: bold;
}

.your-project > div > :nth-child(1) > p {
    width: 80%;
    margin: 20px 0 0;
}

.your-project p{
    color: rgba(30, 30, 30, 0.7);
}

.your-project > div > :nth-child(2) p {
    margin: 10px 0;;
}

.your-project > div > .block{
    flex: 0 0 79%;
    gap: 2em;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 -10px;
}

.your-project > div > .block > .mosaic-block{
    flex-basis: 30%;
    display: flex;
    gap: 20px;
}

.your-project h2{
    margin: -5px;
    color: #61BF95;
    font-family: "NotoSerif";
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 48px;
}


.your-project > div > .block > .mosaic-block > div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.your-project h3 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.your-project p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    margin-top: 5px !important;
}

.your-project a {
    color: #61BF95;
}

nav > div > ul#largeMenu{
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1.5em;
}

nav > div > ul#largeMenu li::before {
    display: none;
  }

nav ul#largeMenu li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
}

nav > div > ul > li > button {
    margin-top: 15px;
}

nav ul#largeMenu li a{
    color: white;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    cursor: pointer;
}

nav ul#largeMenu li a.activeMenu{
    font-weight: bold;
    color: #61BF95 !important;
    padding: 12px;
    margin: -12px;
    background: rgba(97, 191, 149, 0.1);
}

.arrow{
    margin-left: 10px;
}

a{
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
}

nav.light{
    background: #f8f8f8;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 3;
}

nav.light ul li a {
    color: black !important;
}

.line{
    background: linear-gradient(to right, #1E1E1E 80px, #f8f8f8 80px calc(100% - 80px), #1E1E1E calc(100% - 80px) 100%);
    height: 5px;
    position: relative;
    top: 150px;
    z-index: 5;
}

#menuToggle
{
    display: none;
    position: absolute;
    top: 16px;
    right: 10px;

    z-index: 10;

    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span.burger
{
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: white;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
}

#menuToggle span:first-child
{
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
    transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
.menu
{
    display: none;
    position: absolute;
    width: 100vw;
    margin: 0;
    padding: 25px 0;
    right: -10px;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #1e1e1e;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    z-index: 10;
    transform-origin: 0% 0%;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

    overflow-y:scroll;
    max-height: 200vh;
}

/* .menu > div {
    overflow-y:scroll;
    max-height: 99%;
    width: 100%;
} */

.menu li::before{
    display: none;
}


.menu li
{
    padding: 15px;
    font-size: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.menu li:hover{
    background: #61BF95;
}

/*
 * And let's fade it in from the left
 */
#menuToggle input:checked ~ ul
{
    display: flex;
    transform: none;
    opacity: 1;
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 320px) and (max-width: 1025px) {
    nav{
        background: #1e1e1e;
    }

    nav img.logo{
        width: 175px;
        margin: 15px;
    }

    nav > div > ul#largeMenu{
        display: none;
    }

    .menu-block{
        width: 100vw;
    }

    #menuToggle
    {
        display: block;
    }
    
    #menu {
        transform: none;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }

    .resp-buttonAudit{
        margin-top: 0;
        padding: 0;
        text-align: left;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
    }

    .menu li{
        font-size: 16px;
    }

    .menu a li{
        font-size: 14px;
    }

    .menu a{
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 791px) {}

@media only screen and (min-width: 1026px) and (max-width: 1365px) {
    .menu-block{
      transform: scale(0.9);
    }
  }
  
/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}