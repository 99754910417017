
footer{
    background: #1E1E1E;
}

footer > .content-block{
    flex-direction: column;
}

footer p {
    margin: 10px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.grid{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.grid > *:nth-child(2){
    display: flex;
    gap: 3em;
}

.grid-bloc{
    margin: 0;
    display: flex;
    flex-direction: column;
}

.grid-bloc-2{
    width: 400px;
    display: flex;
    flex-direction: column;
}

.grid-bloc-2 > .logo{
    align-self: flex-start;
    margin-left: -10px;
}

.bloc-2{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
}

.bloc-2 > div{
    flex: 1 1 100%;
    padding-right: 10px;
}

.bloc-2 > div > p{
    color: white;
    opacity: 0.6;
    margin: 5px;
    font-size: 16px;
    line-height: 28px;
}


footer hr {
    margin: 20px 0;
}

footer ul {
    list-style-type: none;
    display: flex;
}

footer ul .last {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 0;

}

footer ul li::before{
    display: none;
}

footer ul li{
    margin-right: 20px;
    font-size: 14px;
}

footer p {
    font-size: 14px;
}

@media only screen and (min-width: 320px) and (max-width: 1025px) {
    .grid{
        width: 100%;
        flex-direction: column;
        margin-left: 0;
    }

    .grid > *:nth-child(2){
        flex-direction: column;
    }

    footer{
        background: #1E1E1E;
        padding: 10px 0;
    }

    .grid-bloc{
        width: 100%;
    }

    .grid-bloc-2{
        width: 100%;
    }

    .bloc-2 {
        width: 100%;
        flex-direction: column;
    }

    .bloc-2 > div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .bloc-2 > div > p {
        flex: 1 1 33%;
    }

    .bloc-2 > div > :nth-child(3){
        text-align: end;
    }

    .bloc-2 > *:nth-child(3) {
        padding-top: 0;
        justify-content: flex-start;
        gap: 1em;
    }
    .bloc-2 > div > img{
        margin-bottom: 50px;
    }

    footer hr {
        margin: 50px;
    }

    
    footer ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 50px;
        margin-bottom: 20px;
    }

    .grid-bloc-2 > .logo{
        align-self: center;
        margin-left: 0;
    }

    footer p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    .resp-nav-footer{
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
    }

    .nav-footer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #4F4F4F;
    }

    .grid > *:nth-child(2){
        display: flex;
        gap: 1em;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 1025px) {
    .grid > *:nth-child(2){
        width: 80vw;
        display: flex;
        margin-top: 50px;
        gap: 3em;
        margin-left: calc(50% - 40vw);
    }
}