@font-face {
  font-family: "Roboto";
  src: url("./fonts/roboto-regular-webfont.woff2");
  font-display: swap;
}

@font-face {
  font-family: "NotoSerif";
  src: url("./fonts/notoserif-regular-webfont.woff2");
  font-display: swap;
}

@font-face {
  font-family: "NotoSerif-Bold";
  src: url("./fonts/notoserif-bold-webfont.woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff2");
  font-display: swap;
}

.border-dev{
  border: 1px solid black;
}

*{
  font-family: "Roboto";
}

html, body{
  width: 100vw;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5{
  font-family: "NotoSerif" !important;
}

h1{
  font-size: 56px;
  line-height: 70px;
  margin-bottom: 20px;
}

h1 strong{
  font-family: "NotoSerif-Bold";
}

h2, h3, h4, p{
  color: #1e1e1e;
}

h2, .font-size-40{
  font-weight: bold;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 20px;
}

.h2-64, .h1-64 {
  font-size: 64px;
  line-height: 70px;
}

.h2-48{
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: normal;
}

h3{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

h4, .linkH4{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 10px;
}

.linkH4{
  border: none !important;
}

p{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 10px;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #61BF95;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

li{
  line-height: 26px;
}

a{
  font-size: 16px;
}

button, form.contact input[type="submit"]{
  -webkit-appearance: none;
  background: #61BF95;
  color: white;
  border: none;
  padding: 14.5px 8px;
  cursor: pointer;
  font-weight: bold;
  width: 163px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

button a {
  color: white !important;
  text-decoration: none !important;
}

.buttonCategory{
  color: #61BF95;
  font-weight: bold;
  background: rgba(97, 191, 149, 0.1);
  width: 180px;
}

.menuCategory:hover, .menuCategory.active {
  color: white;
  background: #61BF95;
}

.simulation{
  width: 100%;
  margin-bottom: 10px;
}

.getAnAudit{
  width: 273px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 15px 0;
}

.margin-0{
  margin: 0;
}

.margin-right-100{
  margin-right: 100px;
}

.margin-right-30{
  margin-right: 30px;
}

.margin-right-10{
  margin-right: 10px;
}

.margin-right-20{
  margin-right: 20px;
}

.margin-top-0{
  margin-top: 0;
}

.margin-top-15{
  margin-top: 15px !important;
}

.margin-top-20{
  margin-top: 20px !important;
}

.margin-top-30{
  margin-top: 30px !important;
}

.margin-10-0{
  margin: 10px 0 !important;
}

.margin-30-0{
  margin: 30px 0 !important;
}

.margin-40-0{
  margin: 40px 0;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-top-25{
  margin-top: 25px !important;
}

.margin-top-50{
  margin-top: 50px !important;
}

.margin-top-75{
  margin-top: 75px !important;
}

.margin-top-100{
  margin-top: 100px !important;
}

.margin-bottom-10{
  margin-bottom: 10px !important;
}

.margin-bottom-15{
  margin-bottom: 15px !important;
}

.margin-bottom-25{
  margin-bottom: 25px !important;
}

.margin-bottom-50{
  margin-bottom: 50px !important;
}

.margin-bottom-100{
  margin-bottom: 100px !important;
}

.margin-bottom-75{
  margin-bottom: 75px !important;
}

.margin-bottom-30{
  margin-bottom: 30px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.margin-bottom-0{
  margin-bottom: 0 !important;
}

.margin-left-0{
  margin-left: 0 !important;
}

.margin-left-80{
  margin-left: 80px;
  margin-right: 80px
}

.center{
  text-align: center !important;
}

.text-align-right{
  text-align: right;
}

.text-align-left{
  text-align: left !important;
}

.width-50{
  width: 50% !important;
  margin: 0 auto;
}

.width-75{
  width: 75%;
}

.width-100{
  width: 100%;
}

.flex-center{
  display: flex;
  justify-content: center;
}

.height-100{
  height: 100%;
}

.white{
  color: white !important;
}

.opacity-06{
  opacity: 0.6
}

.opacity-1{
  opacity: 1 !important;
}

.scale-08{
  transform: scale(0.8);
}

.margin-25{
  margin: 25px;
}

.bg-61BF95{
  background: #61BF95;
}

.bg-fff{
  background: #fff;
}

.bg-f1f1f1{
  background: #f1f1f1;
}

.color-61BF95{
  color: #61BF95 !important;
}

input:focus, select:focus {
  outline: none;
}

.block{
  width: 100%;
  display: flex;
}

.block-center{
  align-items: center;
  justify-content: center;
  gap: 5em;
}
.block-30{
  width: 30%;
}

.block-30{
  width: 30%;
}

.block-40{
  width: 40%;
}

.block-50{
  width: 50%;
}

.block-60{
  width: 60%;
}

.block-70{
  width: 70%;
}

.block-80{
  width: 80%;
}

.breadCrumb{
  height: 39px;
  display: inline-block;
  margin-top: 100px;
  align-items: center;
}
.breadCrumb a {
  border-bottom: 1px solid #1e1e1e;
  color: #1e1e1e;
  text-decoration: none !important;
}

section{
  margin-bottom: 25px;
}

.h1-61BF95{
  font-weight: bold;
  font-size: 120px;
  line-height: 100%;
  color: #61BF95;
  margin-bottom: 0;
}

.square-290{
  width: 290px;
  height: 290px;
  background: #61BF95;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.right-1e1e1e{
  background: linear-gradient(to right,white 75%, #1e1e1e 25%);
}

.right-61BF95{
  background: linear-gradient(to right,white 75%, #61BF95 25%);
}

.left-1e1e1e{
  background: linear-gradient(to left,white 75%, #1e1e1e 25%);
}

.left-f1f1f1-75{
  background: linear-gradient(to right,#f1f1f1 75%, white 25%);
}

.padding-0{
  padding: 0 !important;
}

.padding-0-25{
  padding: 0 25px;
}

.padding-25{
  padding: 25px;
}

.padding-40{
  padding: 40px;
}

.padding-25-0{
  padding: 25px 0;
}

.padding-50-0 {
  padding: 50px 0 !important;
}

.padding-100-0 {
  padding: 100px 0 !important;
}

.padding-left-32{
  padding-left: 32px;
}

.padding-right-32{
  padding-right: 32px;
}

.padding-top-25{
  padding-top: 25px;
}

.padding-bottom-10{
  padding-bottom: 10px !important;
}

.padding-20{
  padding: 20px;
}

.padding-0-32{
  padding: 0 32px;
}

.padding-100-32{
  padding: 100px 32px;
}

.margin-bottom-80{
  margin-bottom: 80px;
}

.padding-left-70{
  padding-left: 70px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-align-center{
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.content-block{
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: initial;
  padding: 20px 10px;
  overflow: hidden;
  z-index: 1;
}

.content-block a {
  color: #1e1e1e;
  text-decoration: underline;
}

.content-block ul {
  margin-bottom: 20px;
}

.content-block ul li {
  margin-left: 15px;
}

.flex-column{
  flex-direction: column !important;
}

.flex-row{
  display: flex;
  flex-direction: row !important;
  gap: 2.5em;
}

.gap-1{
  gap: 1em;
}

.gap-0{
  gap: 0 !important;
}

.gap-5{
  gap: 5em;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-space-between{
  justify-content: space-between;
}

.align-items-center{
  align-items: center;
}

button.goLeft, button.goRight{
  width: 80px;
  height: 80px;
}

.goLeft{
  background: #f1f1f1;
}

.goRight{
  background: #61BF95;
}

.border-top-61BF95 {
  border-top: 1px solid#61BF95;
}

.border-bottom-1e1e1e{
  border-bottom: 2px solid#1e1e1E;
}

.border-none{
  border: none !important;
}

.article{
  background: #FFFFFF;
  box-shadow: 0px 6px 12px rgb(0 0 0 / 5%);
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  height: 100%;
}

.articles a, .moreInfos, .linkH4, .mosaic a, .text-decoration-none {
  text-decoration: none !important;
}

.font-NotoSerif{
  font-family: "NotoSerif";
}

.cursor-pointer{
  cursor: pointer;
}

.col-2{
  flex: 1 1 35% !important;
  justify-content: space-between;
  display: flex;
}

.gap-1-5{
  gap: 1.5em;
}

.display-none{
  display: none !important;
}

.display-block{
  display: block !important;
}

.font-size-36{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
}

.font-size-24{
  font-size: 24px;
}

.font-size-20{
  font-size: 20px !important;
}

.flex-basis-15{
  flex-basis: 15%;
}

.flex-basis-30{
  flex-basis: 40%;
}

.flex-stretch{
  align-items: stretch;
}

.flex-start{
  justify-content: flex-start;
}

.display-resp, .resp-display-block{
  display: none;
}

.flex-2-0-30{
  flex: 2 0 30%;
}

.width-45{
  width: 45%;
}

.text-shadow{
  text-shadow: 1px 1px 10px #1e1e1e7a;
}

.flex-space-around{
  justify-content: space-around;
}

.grecaptcha-badge{
  display: none !important;
}

.min-width-0{
  min-width: 0 !important;
}

ol{
  margin-left: 20px;
}

.error{
  color: red !important;
}

.border-error{
  border: 2px solid red !important;
}

.border-bottom-error{
  border-bottom: 2px solid red !important;
}

.font-size-16{
  font-size: 16px !important;
}

iframe{
  height: 100% !important;
}

.width-auto{
  width: auto !important;
}

td {
  padding: 10px;
  border: 1px solid black;
  border-collapse: collapse !important;
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 320px) and (max-width: 1025px) {
  h1{
    width: 100% !important;
    font-size: 24px;
    line-height: 150%;
    text-align: left;
  }

  h2{
    font-size: 22px;
    line-height: 24.5px;
    margin-top: 25px;
  }
  
  h3{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  
  h4, .linkH4{
    font-size: 16px;
    line-height: 22px;
  }

  .linkH4{
    color: white !important;
  }

  section {
    margin-bottom: 0;
  }

  button{
    width: 100%;
  }

  .getAnAudit{
    width: 100%;
  }

  .menu-block > .getAnAudit{
    display: none;
  }
  
  .margin-left-80{
    margin: 0;
    text-align: center;
  }

  .margin-bottom-100{
    margin-bottom: 0;
  }

  .margin-bottom-80, .margin-bottom-75, .margin-bottom-50{
    margin-bottom: 25px !important;
  }

  .padding-25-0{
    padding: 0;
  }

  .padding-50-0 {
    padding: 25px 15px !important;
  }

  .padding-100-0 {
    padding: 100px 15px !important;
  }

  .content-block{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 15px;
  }

  .content-block a, button {
    width: 100%;
  }

  .mosaic a {
    width: auto;
  }

  input[type="submit"]{
    width: 100% !important;
  }

  .block{
    flex-direction: column;
  }
  
  .block-50{
    width: 100%;
  }

  .right-1e1e1e, .right-61BF95, .left-1e1e1e, .left-f1f1f1-75{
    background: white;
  }

  .breadCrumb {
    margin: 70px 0 20px;
    height: 0;
  }

  .breadCrumb a, .breadCrumb span {
    font-size: 12px;
  }

  .col-2{
    flex: 1 1 100% !important;
  }

  h2.center{
    text-align: left;
  }

  h2.width-50{
    width: 100%;
  }

  .square-290{
    width: 43vw;
    height: 43vw;
  }

  .resp-center{
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .resp-margin-top-0{
    margin-top: 0;
  }

  .resp-margin-top-25{
    margin-top: 25px;
  }

  .resp-flex-wrap{
    flex-wrap: wrap;
  }

  .buttonCategory {
    color: #61BF95;
    font-weight: bold;
    background: rgba(97, 191, 149, 0.1);
    width: 135px;
    margin-left: 0;
    padding: 10px;
    font-size: 14px;
  }

  .resp-shadow-61bf95{
    box-shadow: 60px -16px #61bf95;
  }

  .padding-40{
    padding: 0px;
  }

  .mj-embedded-body{
    padding: 5px 0 !important;
  }

  .resp-margin-top-0{
    margin-top: 0;
  }

  .resp-width-40px{
    width: 40px;
  }

  .resp-width-100{
    width: 100% !important;
  }

  .padding-right-32, .padding-left-32{
    padding: 0;
  }

  .resp-display-none{
    display: none !important;
  }

  .resp-margin-bottom-200{
    margin-bottom: 200px;
  }

  .resp-margin-bottom-50{
    margin-bottom: 50px;
  }

  .resp-margin-0{
    margin: 0 !important;
  }
  
  .resp-padding-0{
    padding: 0;
  }

  .display-resp{
    display: flex !important;
  }

  .resp-display-block{
    display: block;
  }

  .resp-stats{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
  }

  .flex-basis-30{
    flex-basis: 100%;
  }

  .h1-61BF95{
    font-size: 60px;
  }

  .h2-64 {
    font-family: "NotoSerif";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
  }

  .h4-stats{
    font-size: 16px;
    line-height: 18px;
  }

  .flex-2-0-30{
    flex: 2 0 100%;
  }

  .resp-flex-column{
    flex-direction: column !important;
  }

  .flex-end {
    justify-content: center;
  }

  .resp-gap-1{
    gap: initial;
  }

  .resp-gap-1-5{
    gap: 1.5em;
  }

  .resp-order-1{
    order: 1
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 791px) {}

@media only screen and (min-width: 1026px) and (max-width: 1365px) {
  .content-block{
    transform: scale(0.9);
  }

  .getAnAudit{
    margin-right: 40px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 2000px) {
  .right-1e1e1e, .right-61BF95, .left-1e1e1e, .left-f1f1f1-75{
    background: white;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1335px) {
  .padding-responsive {
    padding: 20px 40px;
  }
}