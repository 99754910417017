@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.citation{
    background-color: #1e1e1e;
    display: block;
    padding: 10px 80px;
    width: calc(100vw - 160px);
}

.citation > p {
    color: white;
}

p.align-right{
    text-align: right;
}

.photo{
    width: 528px;
    height: 733px;
    border-radius: 10px;
    background: #1e1e1e;
    box-shadow: 18px -18px #00A76D;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.numbers{
    display: flex;
    flex-direction: column;
    width: 130px;
    min-height: 220px;
}

.black-square{
    height: 130px;
    width: 130px;
    border-radius: 10px;
    background: #1e1e1e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.black-square > p {
    color: white;
    font-size: 64px;
}

.slide img {
    margin: 0 auto;
}
  
.slide {
    transform: scale(0.8);
    transition: transform 300ms;
    opacity: 0.8;
}
  
.activeSlide{
    transform: scale(1);
    opacity: 1;
}

.sliderContainer{
    margin-top: 50px;
    cursor: grab
}

.textContainer{
    padding: 15px;
    position: relative;
    top: -150px;
}

.textContainer h2, .textContainer p{
    color: white;
    margin: 0;
}

.sliderContainer .arrow{
    position: absolute;
    cursor: pointer;
    z-index: 10;
    color: white;
    transform: scale(3);
}


.next{
    right: 15%;
    top: 89%;
}

.prev{
    left: 15%;
    top: 89%;
} 

.bg-team{
    background: rgba(30, 30, 30, 0.8);
}

.bg-team h2 {
    color: white;
}

.slider-navigation{
    width: 85%;
    justify-content: space-between;
    margin:0 auto;
    position: relative;
    top: 0;
}

.mosaic-block-partners{
    width: 275px;
    height: 190px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
    box-sizing: border-box;
}

.h4-stats{
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    color: #1E1E1E;
}

.bg-f1f1f1 a {
    color: black;
    text-decoration: underline;
    font-size: 16px;
}

@media only screen and (min-width: 320px) and (max-width: 1025px) {
    .slider-navigation img{
        width: 25px;
    }

    .sliderContainer{
        width: 90vw;
        margin-top: 0;
        margin-bottom: -50px;
    }

    .slide.activeSlide .textContainer p,
    .slide.activeSlide .textContainer h2
    {
        text-align: center;
        margin-top: 5px;
    }

    .slide {
        transform: scale(1);
    }

    .mosaic-block-partners{
        flex: 1 1 30% !important;
        justify-content: space-between;
        display: flex;
        justify-content: center;
        padding: 5px;
        height: auto;
        gap: 1.5em;
    }
}